// src/components/UserNameView.tsx
import React from "react";
import { capitalizeWord } from "../../utils/stringUtil";
import { useNavigate } from "react-router-dom";
import { useUserInfoViewData } from "../../context/UserInfoContext";
import { Button } from "react-bootstrap";

interface UserNameViewProps {
  selectedUser: any;
}

export const UserNameView: React.FC<UserNameViewProps> = ({ selectedUser }) => {
  const navigate = useNavigate();
  const { setUser } = useUserInfoViewData();

  const handleUserSelect = (e: any) => {
    e.stopPropagation();
    // setUser(selectedUser);
    const userIdParam = selectedUser?.id ? `?id=${selectedUser.id}` : "";
    navigate(`/user${userIdParam}`);
  };

  return (
    <div
      onClick={handleUserSelect}
      style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
    >
      {selectedUser.firstName
        ? `${capitalizeWord(selectedUser.firstName)} ${selectedUser.lastName ? capitalizeWord(selectedUser.lastName) : ""}`
        : selectedUser.id || "Unknown User"}
    </div>
  );
};
