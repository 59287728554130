import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Box, TextField } from "@mui/material";
import BanUserButton from './BanUserButton'; // Import the BanUser component
import RemoveUserDislikes from './RemoveUserDislikes';
import RemoveUserLikes from './RemoveUserLikes';

// Add imports for base URL and fetch helpers
import { Base_Url } from '../../constants/constants'; // Adjust path as needed
import useToken from '../Login/useToken'; // Adjust path as needed


export default function UserActionsView({ user }: any) {
  const [customLikeLimit, setCustomLikeLimit] = useState(user.custom_like_limit?.toString() || "");
  const { token } = useToken(); // Move the useToken call here, at the top level
  
  async function saveCustomLikeLimit() {
    const userId = user.id;
    if (!userId || !customLikeLimit) {
      return;
    }
    
    const url = new URL(`${Base_Url}/users/user/like-limit`);
    const bodyData = {
      userId: userId,
      likeLimit: parseInt(customLikeLimit)
    };
    
    // Use the token from the component level, not from inside this function
    
    const options = {
      method: "PUT",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(bodyData),
    };
    
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        console.log(`Set custom like limit for user: ${user.id}`);
        return response.json();
      }
      throw response;
    } catch (error) {
      console.error(error);
    }
  }

  const removeLikes = () => {
    // Implement the logic to remove likes
    console.log("Removing likes...");
  };

  const removeDislikes = () => {
    console.log("Removed dislikes")
  };

  return (
    <div className="UserActionsWrapper">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <BanUserButton user={user}/>
          <RemoveUserLikes userId={user.id} onLikesRemoved={removeLikes} />
          <RemoveUserDislikes userId={user.id} onDislikesRemoved={removeDislikes} />
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <TextField
            size="small"
            id="custom-like-limit"
            label="Custom Like Limit"
            type="number"
            value={customLikeLimit}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              // Only allow numbers
              const value = event.target.value;
              if (value === '' || /^[0-9]+$/.test(value)) {
                setCustomLikeLimit(value);
              }
            }}
          />
          <Button 
            variant="primary" 
            onClick={saveCustomLikeLimit}
          >
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
}
