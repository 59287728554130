import React from "react";
import { FormControlLabel, Switch, Box, Typography } from "@mui/material";

interface MatchesFilterViewProps {
  onlyWithMessages: boolean;
  setOnlyWithMessages: (value: boolean) => void;
}

export const MatchesFilterView: React.FC<MatchesFilterViewProps> = ({
  onlyWithMessages,
  setOnlyWithMessages,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyWithMessages(event.target.checked);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6" component="div" sx={{ mb: 1 }}>
        Filters
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={onlyWithMessages}
            onChange={handleChange}
            name="onlyWithMessages"
            color="primary"
          />
        }
        label="Only show matches with messages"
      />
    </Box>
  );
};
