import React, { useState, useEffect } from "react";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import { MatchesTableView } from "./MatchesTableView";
import { MatchesFilterView } from "./MatchesFilterView";

export const MatchesView = () => {
  const { token } = useToken();
  const [matches, setMatches] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastVisibleId, setLastVisibleId] = useState<string | null>(null);
  const [totalMatches, setTotalMatches] = useState(0);
  const [onlyWithMessages, setOnlyWithMessages] = useState(false);

  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMatches = async () => {
      setLoading(true);
      const url = new URL(`${Base_Url}/matches`);
      url.searchParams.append("pageSize", rowsPerPage.toString());
      if (lastVisibleId) {
        url.searchParams.append("lastVisibleId", lastVisibleId);
      }
      url.searchParams.append("onlyWithMessages", onlyWithMessages.toString());

      const options = {
        method: "GET",
        headers: new Headers({
          Authorization: token,
          "Content-Type": "application/json",
        }),
      };

      try {
        const response = await fetch(url, options);
        if (response.ok) {
          const data = await response.json();
          setMatches(data.matches || []);
          setLastVisibleId(data.lastVisibleId);
          setTotalMatches(data.totalMatches);
        } else {
          throw new Error("Failed to fetch matches");
        }
      } catch (error) {
        console.error("Error fetching matches:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, [token, page, rowsPerPage, onlyWithMessages]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLastVisibleId(null);
  };

  const handleFilterChange = (value: boolean) => {
    setOnlyWithMessages(value);
    setPage(0);
    setLastVisibleId(null);
  };

  const onRowClick = (id: string) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(id)) {
        newExpandedRows.delete(id);
      } else {
        newExpandedRows.add(id);
      }
      return newExpandedRows;
    });
  };

  return (
    <div>
      <MatchesFilterView 
        onlyWithMessages={onlyWithMessages}
        setOnlyWithMessages={handleFilterChange}
      />
      
      <MatchesTableView
        matches={matches}
        loading={loading}
        page={page}
        rowsPerPage={rowsPerPage}
        totalMatches={totalMatches}
        expandedRows={expandedRows}
        onRowClick={onRowClick}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </div>
  );
};
