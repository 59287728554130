import React, { useState, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead, TableSortLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import { UserNameView } from "../UsersManager/UserNameView";
export type ReportDataResponse = { [key: string]: ReportData };

type ReportData = {
  user: any;
  reportDates: Date[];
  _id: string;
  reportingUsers: any[];
};

interface ReportsGridProps {
  reports: ReportDataResponse;
  selected: readonly any[];
  setSelected: any;
}

export const ReportsGrid = (props: ReportsGridProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const { selected, setSelected } = props;
  const [sortField, setSortField] = useState("");
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [expandedRows, setExpandedRows] = useState(new Set());

  const reports = Object.keys(props.reports).map((reportId) => {
    const report = props.reports[reportId];
    return { ...report, id: reportId };
  });

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reports.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = reports.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, restaurant: any) => {
    const selectedIndex = selected.indexOf(restaurant);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, restaurant);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRowClick = (userId: string) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(userId)) {
      newExpandedRows.delete(userId);
    } else {
      newExpandedRows.add(userId);
    }
    setExpandedRows(newExpandedRows);
  };

  const renderPromptData = (promptData: any) => {
    return Object.entries(promptData).map(([key, prompt]: any) => (
      <div key={key}>
        <strong>{prompt.promptKey}:</strong> {prompt.promptValue || "N/A"}
      </div>
    ));
  };

  const isSelected = (user: any) => selected.indexOf(user) !== -1;

  const sortedAndFilteredUsers = useMemo(() => {
    return reports.sort((a: any, b: any) => {
      // if (
      //   sortField === "active_venues" ||
      //   sortField === "likes" ||
      //   sortField === "dislikes"
      // ) {
      //   return orderDirection === "asc"
      //     ? a[sortField].length - b[sortField].length
      //     : b[sortField].length - a[sortField].length;
      // }
      return 0;
    });
  }, [reports, orderDirection, sortField]);

  const handleRequestSort = (property: any) => {
    const isAsc = sortField === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setSortField(property);
  };

  return (
    <div className="userGridWrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow key="header">
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={
                    selected.length > 0 && selected.length < reports.length
                  }
                  checked={
                    reports.length > 0 && selected.length === reports.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "reports"}
                  direction={sortField === "reports" ? orderDirection : "asc"}
                  onClick={() => handleRequestSort("reports")}
                >
                  Reports
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">Users</TableCell>
              <TableCell align="left">Reporting Users</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? sortedAndFilteredUsers.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : sortedAndFilteredUsers
            ).map((report: any, index: any) => {
              const isItemSelected = isSelected(report);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  key={report.id}
                  role="checkbox"
                  onClick={(event) => [
                    handleClick(event, report),
                    handleRowClick(report.id),
                  ]}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">
                    {report.reportDates.length}
                  </TableCell>
                  <TableCell align="left">
                    <UserNameView selectedUser={report.user} />
                  </TableCell>
                  <TableCell align="left">
                    {report.reportingUsers.map((user: any, index: number) => (
                      <UserNameView key={index} selectedUser={user} />
                    ))}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  10,
                  25,
                  50,
                  100,
                  { label: "All", value: -1 },
                ]}
                colSpan={3}
                count={reports.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
