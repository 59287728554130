import React, { useState, useEffect } from "react";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import { SubscriptionsTableView } from "./SubscriptionsTableView";
import { SubscriptionsFilterView } from "./SubscriptionsFilterView";
import { Button } from "react-bootstrap";

export const SubscriptionsView = () => {
  const { token } = useToken();
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [statusFilter, setStatusFilter] = useState("all"); // "all", "active", "expired"
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(false);

  const fetchSubscriptions = async () => {
    setLoading(true);
    const url = new URL(`${Base_Url}/subscriptions`);
    if (statusFilter !== "all") {
      url.searchParams.append("status", statusFilter);
    }

    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        const data = await response.json();
        setSubscriptions(data || []);
      } else {
        throw new Error("Failed to fetch subscriptions");
      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, [token, statusFilter]);

  const handleFilterChange = (value: string) => {
    setStatusFilter(value);
  };

  const onRowClick = (id: string) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(id)) {
        newExpandedRows.delete(id);
      } else {
        newExpandedRows.add(id);
      }
      return newExpandedRows;
    });
  };

  return (
    <div>
      <div className="button">
        <Button variant="primary" onClick={fetchSubscriptions}>
          Refresh
        </Button>
      </div>
      
      <SubscriptionsFilterView 
        statusFilter={statusFilter}
        setStatusFilter={handleFilterChange}
      />
      
      <SubscriptionsTableView
        subscriptions={subscriptions}
        loading={loading}
        expandedRows={expandedRows}
        onRowClick={onRowClick}
      />
    </div>
  );
};
