import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  TableSortLabel,
  CircularProgress,
  Box,
  Collapse,
  Typography,
  Chip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { UserNameView } from "../UsersManager/UserNameView";

interface SubscriptionsTableViewProps {
  subscriptions: any[];
  loading: boolean;
  expandedRows: Set<string>;
  onRowClick: (id: string) => void;
}

export const SubscriptionsTableView: React.FC<SubscriptionsTableViewProps> = ({
  subscriptions,
  loading,
  expandedRows,
  onRowClick,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("desc");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = () => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "active":
        return "success";
      case "grace_period":
      case "billing_retry":
        return "warning";
      case "expired":
      case "revoked":
      case "refunded":
        return "error";
      case "pending":
        return "info";
      default:
        return "default";
    }
  };

  //   const subscriptions = React.useMemo(() => {
  //     return [...subscriptions].sort((a, b) => {
  //       if (orderDirection === "asc") {
  //         return new Date(a.purchaseDate).getTime() - new Date(b.purchaseDate).getTime();
  //       }
  //       return new Date(b.purchaseDate).getTime() - new Date(a.purchaseDate).getTime();
  //     });
  //   }, [subscriptions, orderDirection]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
          <TableRow>
            <TableCell>User ID</TableCell>
            <TableCell>Product ID</TableCell>
            <TableCell>Purchase Date</TableCell>
            <TableCell>Expiration Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Auto Renew</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : subscriptions.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                No subscriptions found
              </TableCell>
            </TableRow>
          ) : (
            subscriptions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((subscription) => (
                <React.Fragment key={subscription._id}>
                  <TableRow
                    hover
                    onClick={() => onRowClick(subscription._id)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "#f5f5f5" },
                    }}
                  >
                    <TableCell>
                      {subscription.userId ? (
                        <UserNameView
                          selectedUser={{ id: subscription.userId }}
                        />
                      ) : (
                        <Typography variant="body2">N/A</Typography>
                      )}
                    </TableCell>
                    <TableCell>{subscription.productId}</TableCell>
                    <TableCell>
                      {formatDate(subscription.purchaseDate)}
                    </TableCell>
                    <TableCell>
                      {formatDate(subscription.expirationDate)}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={subscription.status}
                        color={getStatusColor(subscription.status)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {subscription.autoRenewStatus ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                  {expandedRows.has(subscription._id) && (
                    <TableRow>
                      <TableCell colSpan={5} sx={{ padding: 0 }}>
                        <Collapse in={true} timeout="auto">
                          <Box
                            sx={{ py: 2, px: 3, backgroundColor: "#f9f9f9" }}
                          >
                            <Typography variant="h6" gutterBottom>
                              Subscription Details
                            </Typography>
                            <Box
                              sx={{
                                display: "grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                gap: 2,
                              }}
                            >
                              <Box>
                                <Typography variant="subtitle2">
                                  User ID
                                </Typography>
                                <Typography variant="body2">
                                  {subscription.userId || "N/A"}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="subtitle2">
                                  Original Transaction ID
                                </Typography>
                                <Typography variant="body2">
                                  {subscription.originalTransactionId}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="subtitle2">
                                  Latest Transaction ID
                                </Typography>
                                <Typography variant="body2">
                                  {subscription.latestTransactionId}
                                </Typography>
                              </Box>
                              {subscription.gracePeriodExpirationDate && (
                                <Box>
                                  <Typography variant="subtitle2">
                                    Grace Period Expiration
                                  </Typography>
                                  <Typography variant="body2">
                                    {formatDate(
                                      subscription.gracePeriodExpirationDate
                                    )}
                                  </Typography>
                                </Box>
                              )}
                            </Box>

                            {subscription.events &&
                              subscription.events.length > 0 && (
                                <Box sx={{ mt: 2 }}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ mb: 1 }}
                                  >
                                    Events History
                                  </Typography>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Subtype</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Transaction ID</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {subscription.events.map(
                                        (event: any, index: number) => (
                                          <TableRow key={index}>
                                            <TableCell>{event.type}</TableCell>
                                            <TableCell>
                                              {event.subtype || "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              {formatDate(event.date)}
                                            </TableCell>
                                            <TableCell>
                                              {event.transactionId || "N/A"}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>
                              )}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              count={subscriptions.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
