import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  TableHead,
  LinearProgress,
} from "@mui/material";
import { UserNameView } from "../UsersManager/UserNameView";
import { MatchMessagesView } from "./MatchMessagesView";
import IdCellButton from "../components/IdCellButton";

interface MatchesTableViewProps {
  matches: any[];
  loading: boolean;
  page: number;
  rowsPerPage: number;
  totalMatches: number;
  expandedRows: Set<string>;
  onRowClick: (id: string) => void;
  handlePageChange: (event: unknown, newPage: number) => void;
  handleRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const MatchesTableView: React.FC<MatchesTableViewProps> = ({
  matches,
  loading,
  page,
  rowsPerPage,
  totalMatches,
  expandedRows,
  onRowClick,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  return (
    <TableContainer component={Paper}>
      {loading && <LinearProgress />}
      <Table sx={{ minWidth: 500 }} aria-label="matches table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Users</TableCell>
            <TableCell>Recent Message</TableCell>
            <TableCell>Venue Name</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Request</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matches.map((match: any) => {
            const isRowExpanded = expandedRows.has(match.id);

            return (
              <React.Fragment key={match.id}>
                <TableRow key={match.id} onClick={() => onRowClick(match.id)}>
                  <IdCellButton id={match.id} />
                  <TableCell>
                    {match.users[0] != null && match.users[1] != null && (
                      <div>
                        <UserNameView selectedUser={match.users[0]} />
                        <UserNameView selectedUser={match.users[1]} />
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <p>{match.recentMessage?.messageText || "No message"}</p>
                  </TableCell>
                  <TableCell>
                    {Object.keys(match.venues)?.length || "N/A"}
                  </TableCell>
                  <TableCell>
                    {match.createdAt && match.createdAt._seconds
                      ? new Date(
                          match.createdAt._seconds * 1000 +
                            match.createdAt._nanoseconds / 1000000
                        ).toLocaleString()
                      : "N/A"}{" "}
                  </TableCell>
                  <TableCell>
                    {match.request?.state ?? "None"}
                  </TableCell>
                </TableRow>
                {isRowExpanded && match.recentMessage?.messageText != null && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <MatchMessagesView match={match} />
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              count={totalMatches}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              disabled={loading}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
