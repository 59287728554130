import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Box,
  Typography,
} from "@mui/material";

interface SubscriptionsFilterViewProps {
  statusFilter: string;
  setStatusFilter: (value: string) => void;
}

export const SubscriptionsFilterView: React.FC<
  SubscriptionsFilterViewProps
> = ({ statusFilter, setStatusFilter }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusFilter(event.target.value);
  };

  return (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        mb: 2,
        borderRadius: 2,
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>
          Subscription Filters
        </Typography>

        <FormControl component="fieldset">
          <FormLabel component="legend">Status</FormLabel>
          <RadioGroup
            row
            name="status-filter"
            value={statusFilter}
            onChange={handleChange}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel
              value="active"
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value="expired"
              control={<Radio />}
              label="Expired"
            />
            <FormControlLabel
              value="grace_period"
              control={<Radio />}
              label="Grace Period"
            />
            <FormControlLabel
              value="billing_retry"
              control={<Radio />}
              label="Billing Retry"
            />
            <FormControlLabel
              value="revoked"
              control={<Radio />}
              label="Revoked"
            />
            <FormControlLabel
              value="refunded"
              control={<Radio />}
              label="Refunded"
            />
            <FormControlLabel
              value="pending"
              control={<Radio />}
              label="Pending"
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Paper>
  );
};
